.NavbarSmall {
  overflow_: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 99;
  opacity: 1.0;
  background-color: #FFC90E;
}

.NavbarBig {
  overflow_: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 99;
  opacity: 0.99;
  min-height: 60px;
  background-color: #FFC90E;
}

@media all and  (max-width: 991px) {
  #Test{
    color: white;
    font-weight: bold;
    background-color: #FFC90E;
    padding: 10px;
  }
}

.CloudLoginButton {
  color: #fff !important;
  border: 2px solid #ccc;
  background-color: #FFC90E !important;
  border-color: #fff !important;
  @media (max-width: 991px) {
    font-size: 120%;
    font-weight: bold;
  }   
}

.TopLine {
  color: #fff;
  margin-bottom: 5px;
  font-size: 110%;
}

.navbar-brand {
  padding-top: 0px;
  margin-top: 0px;
  font-size: 300%;
}